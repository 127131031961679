import { t } from "@lingui/macro";
import { ScrollArea, Separator, Accordion } from "@rocket-resume/ui";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import { AccountSettings } from "./_sections/account";
import { ProfileSettings } from "./_sections/profile";
import { SecuritySettings } from "./_sections/security";
import { useState } from "react";
import { SettingSections } from "./_sections/setting-sections";

export const SettingsPage = () => {
  const [section, setSection] = useState<string | null>(null);

  const onOpenSection = (id : string) => {
    setSection(id);
  }

  const onCloseSection = () =>{
    setSection(null);
  }

  return(
    <>
      <Helmet>
        <title>
          {t`Settings`} - {t`Rocket Resume`}
        </title>
      </Helmet>
  
      <div className="max-w-4xl space-y-4">
        <motion.h1
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          className="text-4xl font-bold tracking-tight"
        >
          {t`Settings`}
        </motion.h1>
  
        <ScrollArea hideScrollbar className="h-[calc(100vh-140px)] lg:h-[calc(100vh-88px)]">
          {
            section === null && 
            <div className="space-y-6 xs:p-8">
              <SettingSections onOpenSection={onOpenSection} />
            </div>
          }
          {
            section !== null &&
            <div className="space-y-6">
              <Accordion type="single" defaultValue={section}>
                <AccountSettings onCloseSection={onCloseSection} />
                <SecuritySettings />
                <ProfileSettings />
              </Accordion>
              {/* <Separator />
              <OpenAISettings />
              <Separator />
              <DangerZoneSettings /> */}
            </div>
          }
        </ScrollArea>
      </div>
    </>
  );  
}
